var rp = rp || {};

if (typeof rp.chat == "undefined") {
    if (!document.querySelector("script#realpersonChatLoader")) {
        throw new Error("Could not determine Realperson Chat Suite instance. Ensure that the SCRIPT tag loading loadchatmodule.js has the id 'realpersonChatLoader'.");
    }
    if (!window.rpChatConfig || rpChatConfig.deptId === undefined) {
        throw new Error("Could not find proper configuration for embedding the Realperson Chat Suite. Ensure a 'rpChatConfig' object exists in 'window' and contains a 'deptId' property.");
    }

    rp.chat = {

        isInitiated: false,
        inviteEnabled: 1,
        chatWindow: 'default',

        setChatWindow: function (mode) {
            this.chatWindow = (typeof mode !== 'undefined') ? mode : 'default';

            return true;
        },

        getChatWindow: function () {
            return this.chatWindow;
        },

        enableInvite: function () {
            clearTimeout(rp.chat.Core.resLoadStatus);
            setTimeout(function () {
                rp.chat.startLoadStatus();
            }, 7000);

            return true;
        },

        disableInvite: function () {
            this.inviteEnabled = 0;
            if (rp.chat.BusinessRule) {
                rp.chat.BusinessRule.hideInvitation();
            }
            return true;
        },

        isInviteEnabled: function () {
            return this.inviteEnabled;
        },

        updatedCustomValues: function () {
            if (typeof rp.chat.Core !== 'undefined') {
                rp.chat.Core.loadStatus(1);
            }
            return true;
        },

        triggerBusinessRuleEvent: function (id, eventName, eventPayload) {
            if (typeof rp.chat.Core !== 'undefined') {
                if (typeof rpChatConfig === 'undefined') {
                    rpChatConfig = {};
                }
                rpChatConfig.customValues = rpChatConfig.customValues || {};
                rpChatConfig.customValues.businessRuleEvent = {
                    brEventId: id,
                    brEventName: eventName,
                    brEventPayload: eventPayload
                };

                rp.chat.Core.loadStatus(1);
            }
            return true;
        },

        enable: function () {
            if (typeof rpChatConfig.enabled === "undefined" || rpChatConfig.enabled === false) {
                rpChatConfig.enabled = true;
                rp.chat.Core.loadConfig();
            }
            return this;
        },

        disable: function () {
            rpChatConfig.enabled = false;
            window.clearTimeout(rp.chat.Core.resLoadStatus);
            rp.chat.Core.removeStatusbutton();
            if (rp.chat.BusinessRule) {
                rp.chat.BusinessRule.hideInvitation();
            }

            return this;
        },

        changePage: function (config) {
            if (typeof config !== "undefined") {
                rpChatConfig = config;
            }
            if (typeof rpChatConfig.enabled === "undefined" || rpChatConfig.enabled === true) {
                this.disable().enable();
            } else {
                this.disable();
            }
            return this;
        },

        loadModules: function () {
            if (typeof navigator.cookieEnabled === "boolean" && !navigator.cookieEnabled) {
                if (typeof console !== "undefined") {
                    console.log("Cookies aktivieren um den Chat zu nutzen");
                }
                return;
            }

            rp.chat.isInitiated = true;
            this.TimerProxy = new rp.chat.TimerProxyModule();
            this.Storage = new rp.chat.ChatStorage();
            this.Storage.checkExpire();

            this.Core = new rp.chat.CoreModul();
        },

        loadLayerModul: function (callback) {
            const config = rp.chat.Core.getConfig();

            if (!this.LayerChat && config.chatType === 'layer2' && !rp.chat.Core.isPopupLayer2()) {
                this.LayerChat = new rp.chat.LayerChatModuleV2();
            }

            if (typeof callback === "function") {
                callback();
            }
        },

        loadHeadlessModul: function (callback) {
            const config = rp.chat.Core.getConfig();

            if (!this.HeadlessChat && config.chatType === 'headless' || (typeof rpChatConfig.chatType !== "undefined" && rpChatConfig.chatType === 'headless')) {
                this.HeadlessChat = new rp.chat.HeadlessModule();
            }

            if (typeof callback === "function") {
                callback();
            }
        },

        loadWebRTCModul: function () {
            if (typeof DetectRTC !== 'undefined') {
                DetectRTC.load(function () {
                    if (!(!DetectRTC.isWebRTCSupported || DetectRTC.browser.isIE || DetectRTC.browser.isEdge)) {
                        rp.chat.Core.setWebRTCDetected(1);
                    }
                })
            }
        },

        startLoadStatus: function () {
            this.inviteEnabled = 1;
            clearTimeout(rp.chat.Core.resLoadStatus);
            rp.chat.Core.resLoadStatus = setTimeout(rp.chat.Core.loadStatus, 0);
        },

        checkAvailability: function (callback) {
            const deptId = rp.chat.Core.getDeptId();
            const url = rp.chat.Core.getRPDomain('dialog');

            rp.chat.Network.getJSON(url + '/rest/v1.0/availability/' + deptId)
                .then((data) => {
                    if (typeof callback === "function") {
                        callback(data);
                    }
                })
                .catch(() => {
                    // do nothing
                });
        },

        loadScript: function (url, cb) {
            console.log('rp.chat.loadScript deprecated; use rp.chat.Network.loadScript instead');
            rp.chat.Network.loadScript(url).then(cb);
        }
    };

    rp.chat.CoreModul = function () {
        const that = this;

        const networkHelper = rp.chat.Network;
        const domHelper = rp.chat.DOM;

        const urlCollect = document.scripts.realpersonChatLoader.src.substr(0, document.scripts.realpersonChatLoader.src.indexOf("scripts/loadchatmodul")).slice(0, -1);
        let config = {};
        let status = {};

        const currentUrl = escape(location.toString());
        const referUrl = escape(document.referrer);
        let sessionrp = '';
        let sidOpti = '';

        let countLoadStatusRequests = 0;
        let webRTCDetected = 0;
        let cookieUpdatedOnlineberatung = 0;

        this.resLoadStatus = null;
        this.loadConfigCounter = 0;
        this.loadConfigStartTime = 0;
        this.loadStatusStartTime = 0;
        this.embeddedCanceled = false;
        let configLoaded = 0;
        let chatWasOpened = false;
        this.embeddedLoaded = Date.now();
        let removeLocalSession = false;
        let sessionValidationTimerInSeconds = 2;
        let sessionValidationExpiredInSeconds = 4;
        this.token = '';

        this.getJQ = function () {
            throw new Error('jQuery no longer supported; replace with rp.chat.DOM or vanilla-js');
        };

        this.documentReady = function (fn) {
            if (typeof fn !== 'function') return;
            if (document.readyState === "complete" || document.readyState === "interactive") {
                return fn();
            }
            document.addEventListener('DOMContentLoaded', fn, false);
        };

        /** @deprecated */
        this.loadFile = function (fileType, url, onLoadCallback, integrity)  {
            console.warn('rp.chat.Core.loadFile deprecated, migrate to rp.chat.Network.loadScript or rp.chat.Network.loadStyle');

            const loader = fileType === 'link' ? networkHelper.loadStyle : networkHelper.loadScript;

            loader(url, undefined, integrity).then((event) => {
                if (typeof onLoadCallback === 'function') {
                    onLoadCallback(event);
                }
            });
        }

        this.checkIsCSSFileLoaded = function (url) {
            const styleSheets = document.styleSheets;
            const max = styleSheets.length;
            for (let i = 0; i < max; i++) {
                if (styleSheets[i].href === url)
                    return true;
            }
            return false;
        }

        this.getRPDomain = function (kind) {
            if (typeof kind === "undefined" || kind === "collect") {
                return urlCollect;
            }
            return (config.urlDialog) ? config.urlDialog : "";
        };

        this.getEmbedApiBase = function (useCollect) {
            const base = useCollect ? urlCollect : config.urlDialog;
            return base.replace(/\/system$/, '/api/embed');
        }

        this.getStatus = function () {
            return status;
        };

        this.getTimeOffset = function () {
            return (typeof config.timeOffset !== "undefined") ? config.timeOffset : 0;
        };

        this.startChat = function (invitetyp, kind) {
            if (Object.keys(config).length === 0) {
                return false;
            }

            let kindString = "";
            if (typeof kind == "undefined") {
                kindString = "text";
            } else {
                const pattern = /(video|text)/i;
                if (pattern.test(kind)) {
                    kindString = kind;
                } else {
                    kindString = "text";
                }
            }

            // reset local session validation
            removeLocalSession = false;

            // set session cookie if deferred
            if (
                typeof rpChatConfig !== 'undefined' && rpChatConfig.deferLocalState === true
                || (config.requireCookieConsent === true && rpChatConfig.checkTrackingAllowed())
            ) {
                setCookieSession()
            }

            const businessRuleId = (rp.chat.BusinessRule) ? rp.chat.BusinessRule.getCurrentBusinessRuleId(invitetyp) : 0;

            if (rp.chat.BusinessRule) {
                    rp.chat.BusinessRule.resetRuleId();
                }// Akanoo autoinvite accept
            if (businessRuleId && window.at && rpChatConfig.customValues && rpChatConfig.customValues.businessRuleEvent && rpChatConfig.customValues.businessRuleEvent.brEventPayload) {
                window.at('trigger', 'claim', rpChatConfig.customValues.businessRuleEvent.brEventPayload.measure);
            }

            if (rp.chat.BusinessRule) {
                rp.chat.BusinessRule.hideInvitation();
            }

             if (
                (typeof rpChatConfig.chatType === 'undefined' || rpChatConfig.chatType !== 'headless')
                && (config.chatType === 'layer2')
            ) {
                if (rp.chat.Core.isPopupLayer2()) {
                    rp.chat.updatedCustomValues();
                    const winTop = (typeof screen !== "undefined" && screen.height >= config.chatWindow.size.height) ? 0 : 100;
                    const params = 'location=no,menubar=no,scrollbars=no,resizable=1,screenX=50,screenY=' + winTop + ',width=' + 430 + ',height=' + 600;
                    const url = rp.chat.Core.getRPDomain('dialog') + "/layer-v2/popup/" + rp.chat.Core.getDeptId() + '/' + invitetyp + '/' + kindString + '/' + businessRuleId + '/' + sessionrp;
                    window.open(url, 'rp-layer2-popup', params);
                    return false;
                }
                rp.chat.Core.loadLayerChatModuleV2(() => {
                    triggerEvent(document, 'rp-start-chat');
                    rp.chat.LayerChat.setLayer(invitetyp, kindString, businessRuleId);
                });
            } else if (config.chatType === 'headless' || (typeof rpChatConfig.chatType !== 'undefined' && rpChatConfig.chatType === 'headless')) {
                rp.chat.Core.loadHeadlessChatModule(() => {
                    triggerEvent(document, 'rp-start-chat');
                    rp.chat.HeadlessChat.setHeadless(invitetyp, kindString, businessRuleId);
                });
            }

            chatWasOpened = true;

            return false;
        };

        this.removeStatusbutton = function () {
            domHelper("span#optiRealPersonContent #realperson_text_status_button").remove();
            domHelper("span#optiRealPersonContent #realperson_video_status_button").remove();
        };

        this.getCurrentUrl = function () {
            return currentUrl;
        };

        this.getDeptId = function () {
            return (typeof rpChatConfig !== "undefined" && typeof rpChatConfig.deptId !== "undefined") ? rpChatConfig.deptId : 0;
        };

        this.getSessionRp = function () {
            return escape(sessionrp);
        };

        this.getSidOpti = function () {
            if (!sidOpti) {
                isValidCookieOnlineBeratung();
            }
            return escape(sidOpti);
        };

        this.getWebRTCDetected = function () {
            return webRTCDetected;
        };

        this.setWebRTCDetected = function (value) {
            webRTCDetected = value;
        };

        this.getConfig = function () {
            return config;
        };

        this.getBrowserWidth = function () {
            return (document.body.offsetWidth) ? document.body.offsetWidth : (window.outerWidth) ? window.outerWidth : 0;
        };

        this.getBrowserHeight = function () {
            return (document.body.offsetWidth) ? window.innerHeight : (document.body.offsetHeight) ? document.body.offsetHeight : 0;
        };

        function setConfigLoaded(status) {
            configLoaded = status;
        }

        this.loadConfig = function (callback) {
            if (typeof rpChatConfig !== 'undefined'
                && typeof rpChatConfig.enabled !== 'undefined'
                && !rpChatConfig.enabled
            ) {
                return;
            }

            if (typeof rpChatConfig === 'undefined') {
                rpChatConfig = {};
            }
            rpChatConfig.enabled = true;

            setConfigLoaded(1);

            this.documentReady(function () {
                that.loadConfigCounter++;
                that.loadConfigStartTime = Date.now();

                const request_url = that.getEmbedApiBase(true) + '/config';
                let customValue = {};
                if (typeof rpChatConfig !== 'undefined') {
                    customValue = rpChatConfig.customValues || {};
                }

                const params = {
                    deptid: that.getDeptId(),
                    pageurl: currentUrl,
                    session_rp: sessionrp,
                    deptlist: getCookieDeptList(),
                    referer: referUrl,
                    screen_width: document.body.clientWidth,
                    customValues: JSON.stringify(customValue).replace(/<.*?>/g, ''),
                    load_config_counter: that.loadConfigCounter,
                    current_time: Math.floor((new Date()).getTime() / 1000),
                    cpid: (new RegExp('cpid=([^&#]*)').exec(window.location.href) !== null) ? (new RegExp('cpid=([^&#]*)').exec(window.location.href))[1] : customValue.cpid || '',
                    hasStatusButtonPlaceholder: typeof rpChatConfig.isInternal !== 'undefined' ? 1 :document.querySelectorAll('#optiRealPersonContent').length
                };

                networkHelper.postJSON(request_url, params)
                    .then((data) => that.loadConfigSuccess(data, callback));
            });
        };

        this.isPopupLayer2 = function() {
            let forcePopup = config && !!config.forcePopup;
            if (window.rpChatConfig && typeof rpChatConfig.forcePopup !== "undefined") {
                forcePopup = !!rpChatConfig.forcePopup;
            }
            return forcePopup;
        }

        this.checkConfigProcessingDurationIsTooLong = function (startTime) {
            let maxTimeInSeconds = 8;
            let currentTime = Date.now();
            return (currentTime - startTime) > (maxTimeInSeconds * 1000);
        }

        this.checkStatusProcessingDurationIsTooLong = function (startTime) {
            let maxTimeInSeconds = 10;
            let currentTime = Date.now();
            return (currentTime - startTime) > (maxTimeInSeconds * 1000);
        }

        this.loadConfigSuccess = function (data) {
            that.token = data?.auth?.token || that.token;

            if (typeof data.chat_id === 'undefined' && this.checkConfigProcessingDurationIsTooLong(that.loadConfigStartTime)) {
                this.embeddedCanceled = true;
            }

            if (that.loadConfigCounter != data.loadConfigCounter) {
                return false;
            }

            if (typeof data.trackingIsAllowed === "boolean"
                && typeof rp.chat.Tcf !== "undefined"
            ) {
                rp.chat.Tcf.setIsAllowed(data.trackingIsAllowed);
            }

            if (typeof data.cobrowsing !== "undefined" && typeof data.cobrowsing.url !== "undefined") {
                window.location.href = data.cobrowsing.url;
            }

            rpChatConfig.deptId = data.deptid;

            if (typeof data.hasRealpersonLogin !== "undefined") {
                if (typeof rpChatConfig.customValues === "undefined") {
                    rpChatConfig.customValues = {};
                }
                rpChatConfig.customValues.hasRealpersonLogin = data.hasRealpersonLogin;
            }

            if (typeof data.whitelistblocked !== 'undefined') {
                return false;
            }

            config = Object.assign({}, config, data);

            // only set session cookies immediately if not deferred
            // AND cookie consent is not required,
            // OR consent is required and cookies have been consented to
            if (
                (typeof rpChatConfig !== 'undefined' && rpChatConfig.deferLocalState !== true)
                && (
                    (config.requireCookieConsent === false)
                    || (config.requireCookieConsent === true && rpChatConfig.checkTrackingAllowed())
                )
            ) {
                setCookieSession();
            } else {
                sessionrp = config.createSessionRp;
                // cookie will later be set on startChat
            }

            transferExtCookieOnlineBeratung();

            if (!this.embeddedCanceled && typeof config.businessRule !== "undefined") {
                networkHelper.loadScript(rp.chat.Core.versionizeUrl(urlCollect + "/scripts/loadbusinessrules.js"))
                    .then(() => {
                        rp.chat.BusinessRule = new rp.chat.BusinessRuleModule();
                        rp.chat.BusinessRule.registerEvents();
                    });
            }

            if (!this.embeddedCanceled && typeof config.checkAdBlock !== "undefined" && typeof rp.chat.AdBlock === "undefined") {
                networkHelper.loadScript(rp.chat.Core.versionizeUrl(urlCollect + "/third-party/blockadblock/blockadblock.js"), null, "sha512-fAGGNhSTF6aPlCNbvpiCJqvBPWCDpfGr5OqFbV+cWAoygArB4IB8w9e8VJNUF9dzK1tPVH7Cdqxg9flSIRFb8A==");
            }

            if (typeof DetectRTC === "undefined") {
                if (typeof config.hasVideoSupport !== "undefined" && config.hasVideoSupport) {
                    networkHelper.loadScript(rp.chat.Core.versionizeUrl(urlCollect + "/third-party/detectrtc/DetectRTC.min.js"), null, "sha512-NSFwhu8pRRcv5hpvXd4Uvf1TSSoT37Zt1mENNDTs1eRAriymDt3VNrTxouz99rnlXO1Adb92jBWWRCLGEUbDkw==")
                        .then(() => {
                            rp.chat.loadWebRTCModul();
                        });
                }
            } else {
                rp.chat.loadWebRTCModul();
            }

            if (typeof config.customTracking !== "undefined") {
                networkHelper.loadScript(rp.chat.Core.versionizeUrl(urlCollect + "/layouts/" + config.theme + "/js/loadCustomTrack.js"));
            }

            if (typeof config.statusbutton.text.css !== "undefined") {
                const style = document.createElement('style');
                style.appendChild(document.createTextNode(config.statusbutton.text.css));
                document.head.appendChild(style);
            }

            if (typeof config.statusbutton.video.css !== "undefined") {
                const style = document.createElement('style');
                style.appendChild(document.createTextNode(config.statusbutton.video.css));
                document.head.appendChild(style);
            }

            let isGeneralCssLoaded = rp.chat.Core.checkIsCSSFileLoaded(rp.chat.Core.versionizeUrl(urlCollect + "/css/realperson-code.css"));
            if (isGeneralCssLoaded === false) {
                networkHelper.loadStyle(rp.chat.Core.versionizeUrl(urlCollect + "/css/realperson-code.css"))
                    .then(() => {
                        isGeneralCssLoaded = true;
                     });
            }

            let isThemeCssLoaded = rp.chat.Core.checkIsCSSFileLoaded(rp.chat.Core.versionizeUrl(urlCollect + "/layouts/" + config.theme + "/css/realperson-code.css"));
            if (config.theme && isThemeCssLoaded === false) {
                networkHelper.loadStyle(rp.chat.Core.versionizeUrl(urlCollect + "/layouts/" + config.theme + "/css/realperson-code.css"))
                    .then(() => {
                        isThemeCssLoaded = true;
                    });
            }

            let isLayerChatModuleLoaded = false;
            if (config.isLayerChat) {
                if (config.chatType === 'layer2') {
                    if (!rp.chat.Core.isPopupLayer2()) {
                        rp.chat.Core.loadLayerChatModuleV2(function () {
                            isLayerChatModuleLoaded = true;
                            if (rp.chat.LayerChat.getChatLayerIsActive() === 0) {
                                rp.chat.Storage.destroy();
                            }
                        });
                    }
                    triggerEvent(document, 'rp-onlinestatus', {
                        text: !!config.statusbutton.text.onlinestatus,
                        video: !!config.statusbutton.video.onlinestatus
                    });
                }
            } else {
                triggerEvent(document, 'rp-onlinestatus', {
                    text: !!config.statusbutton.text.onlinestatus,
                    video: !!config.statusbutton.video.onlinestatus
                });
            }

            let isHeadlessChatModuleLoaded = false;
            if (config.chatType === 'headless' || (typeof rpChatConfig.chatType !== "undefined" && rpChatConfig.chatType === 'headless')) {
                rp.chat.Core.loadHeadlessChatModule(function () {
                    isHeadlessChatModuleLoaded = true;
                });
            }

            if (typeof data.cobrowsing2 !== 'undefined' && config.chatType === 'layer2') {
                networkHelper.loadScript(rp.chat.Core.versionizeUrl(urlCollect + "/../js/cobrowsing/cobrowsing-v2-client.js"), "realperson-cobrowsingmodul2")
                    .then(() => {
                        rp.chat.Cobrowsing2 = new rp.chat.CobrowsingModul2();
                        rp.chat.Cobrowsing2.initialize();
                    });
            }

            if (typeof data.cobrowsing !== 'undefined' && typeof data.cobrowsing.cookieList !== 'undefined') {
                for (const key in data.cobrowsing.cookieList) {
                    const cookie = data.cobrowsing.cookieList[key];
                    rp.chat.Core.writeCookie(cookie["name"], cookie["content"]);
                    window.document.location.href = data.cobrowsing.url;
                }
            }

            if (typeof config.cookie !== 'undefined') {
                Object.keys(config.cookie).forEach(function (key) {
                    if (typeof config.cookie[key].deleteCookieOnlineberatung !== 'undefined') {
                        document.cookie = config.cookie[key].name + "=; path=/; expires=Fri, 02 Jan 1970 00:00:00 UTC; domain=" + getFirstDomainName() + (window.location.protocol === 'https:' ? ";secure" : "")+ " ;SameSite=Lax";
                        }
                    });
                }

                if (typeof callback === "function") {
                    callback();
            }

            var awaitCss = function () {
                if (!isGeneralCssLoaded || !isThemeCssLoaded) {
                    setTimeout(awaitCss, 50);
                    return;
                }
                loadStatusbutton('text');
                loadStatusbutton('video');

                domHelper(".optiRealPersonStatusbutton .realpersonFadeIn").each(function(el) {
                    const $that = domHelper(el);
                    if (!$that.hasClass('realpersonDisplayOnceGlobal') || !window.sessionStorage.getItem("rp-chat-storage-statusbutton-display-once")) {
                        el.style.opacity = 0;
                        setTimeout(() => {
                            $that.fadeIn('slow');
                        }, el.dataset.fadeInTime);
                    }
                });
                domHelper(".optiRealPersonStatusbutton .realpersonFadeOut").each(function(el) {
                    setTimeout(() => {
                        el.style.opacity = 0;
                    }, el.dataset.fadeOutTime);
                });
                domHelper(".optiRealPersonStatusbutton .realpersonDisplayOnceGlobal").each(function(el) {
                    if (window.sessionStorage.getItem("rp-chat-storage-statusbutton-display-once")) {
                        el.style.opactiy = 0;
                    }
                    window.sessionStorage.setItem("rp-chat-storage-statusbutton-display-once", true);
                });
                domHelper('.optiRealPersonStatusbutton .realpersonClose').on('click', (e) => {
                    e.preventDefault();
                    domHelper('.' + e.target.dataset.closeTarget).remove();
                });
            };
            awaitCss();

            rp.chat.Core.loadStatus();


            if (config.campaign_active) {
                var awaitCampaignDependencies = function () {
                    if (config.isLayerChat && !isLayerChatModuleLoaded) {
                        setTimeout(awaitCampaignDependencies, 50);
                        return;
                    }

                    var capacity_exist = !!config.statusbutton.text.onlinestatus;

                    if (config.campaign_data.show_always > 0) {
                        if (!capacity_exist) {
                            // event -> offline scenario
                            rp.chat.Core.startChat(41, 'text');
                        } else {
                            rp.chat.Core.startChat(40, 'text');
                            // event -> campaign success
                        }
                    } else {
                        if (capacity_exist) {
                            // event campaign success
                            rp.chat.Core.startChat(40, 'text');
                        }
                    }
                };
                awaitCampaignDependencies();
            }
        };

        this.loadLayerChatModuleV2 = function (callback) {
            if (typeof rp.chat.LayerChat === 'object') {
                rp.chat.LayerChat.initLayerContent(callback);
                if (removeLocalSession || typeof config.removeLayerChatCookie !== 'undefined') {
                    rp.chat.LayerChat.closePreChat();
                    rp.chat.Storage.destroy();
                }
                if (removeLocalSession) {
                    rp.chat.LayerChat.removeCookie();
                    rp.chat.LayerChat.removeCookie('REALPERSON_SESSION');
                    sessionrp = '';
                    removeLocalSession = false;
                    rp.chat.Core.loadConfig();
                }
                return;
            }

            networkHelper.loadScript(rp.chat.Core.versionizeUrl(urlCollect + "/scripts/loadlayerchatmodul-v2.js"))
                .then(() => {
                    rp.chat.loadLayerModul(function () {
                        rp.chat.LayerChat.bindEvents();
                        if (removeLocalSession || typeof config.removeLayerChatCookie !== "undefined") {
                            rp.chat.LayerChat.closePreChat();
                            rp.chat.Storage.destroy();
                        }

                        if (removeLocalSession) {
                            rp.chat.LayerChat.removeCookie();
                            rp.chat.LayerChat.removeCookie('REALPERSON_SESSION');
                            removeLocalSession = false;
                            sessionrp = '';
                            rp.chat.Core.loadConfig(function () {
                                rp.chat.LayerChat.createLayer(callback);
                            });
                        } else {
                            rp.chat.LayerChat.createLayer(callback);
                        }
                    });
               });
        };

        this.loadHeadlessChatModule = function (callback) {
            if (typeof rp.chat.HeadlessChat === 'object') {
                rp.chat.HeadlessChat.initHeadless(callback);
                return;
            }

            networkHelper.loadScript(rp.chat.Core.versionizeUrl(urlCollect + "/scripts/loadheadlesschatmodul.js"))
                .then(() => {
                    rp.chat.loadHeadlessModul(function () {
                        rp.chat.HeadlessChat.createHeadless(callback);
                    });
                });
        }

        this.loadStatus = function (onlySaveStatus) {
            if (
                (config.isLayerChat && !rp.chat.Core.isPopupLayer2() && typeof rp.chat.LayerChat == 'undefined')
                ||
                (typeof config.checkAdBlock !== "undefined" && typeof rp.chat.Core.getHasAdBlockDetected === "function" && !rp.chat.Core.getHasAdBlockDetected())
            ) {
                // wenn LayerChat, oder AdBlock ermitteln dann darauf warten das JS-Datei fertig mit laden ist
                this.resLoadStatus = setTimeout(rp.chat.Core.loadStatus, 2);
                return;
            }

            var isOnlySaveStatus = (typeof onlySaveStatus !== 'undefined');

            if (!sessionrp || sessionrp === '') {
                // abort status if unknown session as the backend refuses these requests anyway
                if (isOnlySaveStatus) return;
                rp.chat.Core.resLoadStatus = setTimeout(rp.chat.Core.loadStatus, 60000);
                return;
            }

            var chatLayer = 0;
            if (typeof rp.chat.LayerChat !== 'undefined') {
                chatLayer = rp.chat.LayerChat.getChatLayerIsActive();
            }
            if (typeof rp.chat.HeadlessChat !== 'undefined') {
                chatLayer = rp.chat.HeadlessChat.chatIsActive() ? 1 : 0;
            }

            var request_url = rp.chat.Core.getEmbedApiBase(true) + '/status';

            var customValue = {};
            if (typeof rpChatConfig !== 'undefined') {
                customValue = rpChatConfig.customValues || {};
            }

            var isInviteEnabled = 1;
            if (typeof rp.chat.LayerChat !== 'undefined' && chatLayer) {
                isInviteEnabled = 0;
            }
            if (typeof rp.chat.HeadlessChat !== 'undefined' && chatLayer) {
                isInviteEnabled = 0;
            }

            this.resLoadStatus = null;
            this.loadStatusStartTime = Date.now();

            var params = {
                deptid: rp.chat.Core.getDeptId(),
                pageurl: currentUrl,
                inviteEnabled: (rp.chat.inviteEnabled && (tabActive || typeof status.autoinvite != 'undefined')) ? isInviteEnabled : 0,
                chatlayer: chatLayer,
                customValues: JSON.stringify(customValue),
                adblock_detected: (typeof rp.chat.Core.getAdBlockDetected === "function") ? rp.chat.Core.getAdBlockDetected() : 0,
                count_status_requests: countLoadStatusRequests,
                session_rp: sessionrp,
            };

            networkHelper.postJSON(request_url, params)
                .then((data) => {
                    status = data;
                    that.token = data?.auth?.token || that.token;
                    if (rp.chat.Core.embeddedCanceled) return;

                    if (typeof data.chat_id === 'undefined' && that.checkStatusProcessingDurationIsTooLong(this.loadStatusStartTime)) {
                        that.embeddedCanceled = true;
                        removeStatusButton('text');
                        removeStatusButton('video');
                        return;
                    }

                    if (typeof status.autoinvite !== "undefined"
                        && typeof rp.chat.BusinessRule === "undefined"
                    ) {
                        networkHelper.loadScript(rp.chat.Core.versionizeUrl(urlCollect + "/scripts/loadbusinessrules.js"))
                            .then(() => {
                                rp.chat.BusinessRule = new rp.chat.BusinessRuleModule();
                                rp.chat.BusinessRule.registerEvents();
                                rp.chat.BusinessRule.checkInviteStatus();
                            });
                    }
                    if (rp.chat.BusinessRule) {
                        rp.chat.BusinessRule.checkInviteStatus();
                    }

                    if (isOnlySaveStatus) return;
                    checkCobrowsingStatus();

                    if (typeof config.cookie !== 'undefined') {
                        Object.keys(config.cookie).forEach(function (key) {
                            if (typeof config.cookie[key].removeByMidnight !== 'undefined' && config.cookie[key].removeByMidnight
                                && cookieUpdatedOnlineberatung !== 0 && cookieUpdatedOnlineberatung !== (new Date()).getDate()
                            ) {
                                document.cookie = config.cookie[key].name + "=; path=/; expires=Fri, 02 Jan 1970 00:00:00 UTC; domain=" + getFirstDomainName() + (window.location.protocol === 'https:' ? ";secure" : "") + " ;SameSite=Lax";
                            } else if (typeof config.cookie[key].continuousUpdated !== 'undefined' && config.cookie[key].continuousUpdated) {
                                updateCookieOnlineBeratung(key);
                            }
                        });
                    }

                    if (typeof status.checkStatus === 'undefined') {
                        status.checkStatus = 60;
                    }

                    if (status.checkStatus > 0
                        && !that.embeddedCanceled
                    ) {
                        that.resLoadStatus = setTimeout(rp.chat.Core.loadStatus, (status.checkStatus * 1000));
                    }
                })
            .catch(() => {
                if (isOnlySaveStatus || rp.chat.Core.embeddedCanceled) return;
                that.resLoadStatus = setTimeout(rp.chat.Core.loadStatus, 60000);
            });

            countLoadStatusRequests = countLoadStatusRequests + 1;
        };

        var checkCobrowsingStatus = function () {
            if ((typeof status.cobrowsing !== 'undefined') && (status.cobrowsing.load)) {
                if (status.cobrowsing.version2) {
                    if (document.getElementById('realperson-cobrowsingmodul2') == null) {
                        networkHelper.loadScript(rp.chat.Core.versionizeUrl(urlCollect + "/../js/cobrowsing/cobrowsing-v2-client.js"), "realperson-cobrowsingmodul2")
                            .then(() => {
                                rp.chat.Cobrowsing2 = new rp.chat.CobrowsingModul2();
                                rp.chat.Cobrowsing2.initialize();
                        });
                    }
                }
            }
        };

        const removeStatusButton = function (type) {
            const button = document.getElementById('realperson_' + type + '_status_button');
            if (button) {
                button.remove();
            }
        }

        var loadStatusbutton = function (type) {
            const img = document.createElement('img');
            img.id = 'RealpersonChatStatusButton' + type;

            if (config.statusbutton[type].src !== undefined || rp.chat.Core.getTextLinkCaption() !== "") {
                removeStatusButton(type);

                const div = document.createElement('div');
                div.id = 'realperson_' + type + '_status_button';
                div.title = config.statusbutton[type].infotext;

                let divClassList = '';
                if (config.statusbutton[type].addClass !== undefined && config.statusbutton[type].addClass !== '') {
                    divClassList += config.statusbutton[type].addClass;
                }
                if (config.statusbutton[type].animation !== undefined) {
                    divClassList += ' realperson-animated ' + config.statusbutton[type].animation;
                }

                if (divClassList.length > 0) {
                    div.setAttribute('class', divClassList);
                }

                if (config.statusbutton[type].inlineCSS !== undefined && config.statusbutton[type].inlineCSS !== '') {
                    div.setAttribute('style', config.statusbutton[type].inlineCSS);
                }

                const link = document.createElement('a');
                link.id = 'RealpersonChatStatusButtonLink' + type;
                link.href = "javascript:void(0);";
                link.style.display = 'none';

                if (rp.chat.Core.getTextLinkCaption() !== "") {
                    link.innerText = rp.chat.Core.getTextLinkCaption();
                } else if (config.statusbutton[type].buttonType !== undefined && config.statusbutton[type].buttonType === 'html') {
                    if (config.statusbutton[type].onlinestatus || (!config.statusbutton[type].onlinestatus && config.statusbutton[type].offlineClick)) {
                        if (config.statusbutton[type].clickClass !== undefined && config.statusbutton[type].clickClass !== '') {
                            if (config.statusbutton[type].delay !== undefined) {
                                setTimeout(() => {
                                    div.innerHTML = config.statusbutton[type].src;
                                    updateFootprint();
                                }, config.statusbutton[type].delay * 1000);
                            } else {
                                div.innerHTML = config.statusbutton[type].src;
                            }
                        } else {
                            link.innerHTML = config.statusbutton[type].src;
                            if (config.statusbutton[type].delay !== undefined) {
                                setTimeout(() => {
                                    div.appendChild(link);
                                    updateFootprint();
                                }, config.statusbutton[type].delay * 1000);
                            } else {
                                div.innerHTML = config.statusbutton[type].src;
                            }
                        }
                    } else {
                        div.innerHTML = config.statusbutton[type].src;
                    }
                } else {
                    img.src = config.statusbutton[type].src;
                    img.title = config.statusbutton[type].infotext;
                    img.alt = config.statusbutton[type].infotext;
                }

                if (rp.chat.Core.getTextLinkCaption() !== "") {
                    if (config.statusbutton[type].delay !== undefined) {
                        setTimeout(function () {
                            div.appendChild(link);
                            domHelper("span#optiRealPersonContent").append(div);
                            updateFootprint();
                        }, config.statusbutton[type].delay * 1000);
                    } else {
                        div.appendChild(link);
                        domHelper("span#optiRealPersonContent").append(div);
                    }
                } else if (config.statusbutton[type].buttonType !== undefined && config.statusbutton[type].buttonType === 'html') {
                    if (config.statusbutton[type].delay !== undefined) {
                        setTimeout(function () {
                            domHelper("span#optiRealPersonContent").append(div);
                            updateFootprint();
                        }, config.statusbutton[type].delay * 1000);
                    } else {
                        domHelper("span#optiRealPersonContent").append(div);
                    }
                    if (config.statusbutton[type].onlinestatus || (!config.statusbutton[type].onlinestatus && config.statusbutton[type].offlineClick)) {
                        if (config.statusbutton[type].clickClass !== undefined && config.statusbutton[type].clickClass !== '') {
                            domHelper("span#optiRealPersonContent").on('click', '#realperson_' + type + '_status_button .' + config.statusbutton[type].clickClass, (e) => {
                                e.preventDefault();
                                if (domHelper(e.target).hasClass("realpersonClose")) {
                                    return;
                                }
                                if (domHelper('#realperson_' + type + '_status_button').hasClass("rp-invite-active")) {
                                    return;
                                }
                                rp.chat.Core.startChat(1, type);
                            });
                        } else {
                            domHelper('#realperson_' + type + '_status_button').on('click', (e) => {
                                e.preventDefault();
                                if (domHelper(e.target).hasClass("realpersonClose")) {
                                    return;
                                }
                                if (domHelper('#realperson_' + type + '_status_button').hasClass("rp-invite-active")) {
                                    return;
                                }
                                rp.chat.Core.startChat(1, type);
                            });
                        }
                    }
                } else {
                    if (config.statusbutton[type].onlinestatus || (!config.statusbutton[type].onlinestatus && config.statusbutton[type].offlineClick)) {
                        link.appendChild(img);
                        div.appendChild(link);
                        if (typeof config.statusbutton[type].delay !== 'undefined') {
                            setTimeout(() => {
                                domHelper("span#optiRealPersonContent").append(div);
                                updateFootprint();
                            }, config.statusbutton[type].delay * 1000);
                        } else {
                            domHelper("span#optiRealPersonContent").append(div);
                        }

                        domHelper("span#optiRealPersonContent").on('click', '#realperson_' + type + '_status_button', (e) => {
                            e.preventDefault();
                            if (domHelper(e.target).hasClass("realpersonClose")) return;
                            rp.chat.Core.startChat(1, type);
                        });
                    } else {
                        div.appendChild(img);
                        domHelper("span#optiRealPersonContent").append(div);
                    }
                }
                if (rp.chat.TrackModule !== undefined && rp.chat.TrackModule.displayStatusButton !== undefined) {
                    rp.chat.TrackModule.displayStatusButton(!!config.statusbutton[type].onlinestatus);
                }
            }
        };

        var setCookieSession = function () {
            if (!isValidCookieSession()) {
                sessionrp = (typeof config.createSessionRp !== "undefined") ? config.createSessionRp : "";
                document.cookie = "REALPERSON_SESSION=" + sessionrp + "; path=/; domain=" + getFirstDomainName() + (window.location.protocol === 'https:' ? ";secure" : "") + " ;SameSite=Lax";
            }
        };

        var isValidCookieSession = function () {
            var content = getCookieContent("REALPERSON_SESSION=");
            if (content !== false) {
                sessionrp = content;
            }
            return content;
        };


        var setCookieOnlineBeratung = function (cookieContent, cookieKey) {
            if (!rpChatConfig.checkTrackingAllowed()) {
                return false;
            }

            var cookieKey = (typeof cookieKey !== "undefined") ? cookieKey : "default";
            var cookieConfig = config.cookie[cookieKey];
            var date = new Date();
            date.setTime(date.getTime() + (cookieConfig.lifetime * 1000));

            if (typeof cookieContent === "undefined") {
                if (cookieKey !== "default") {
                    cookieContent = (typeof cookieConfig.content !== "undefined") ? cookieConfig.content : getCookieOnlineBeratung();
                    if (cookieConfig.name == 'ONLINEBERATUNG') {
                        sidOpti = cookieContent
                    }

                } else {
                    cookieContent = (new Date()).getTime() + that.getTimeOffset() * 1000;
                    if (cookieConfig.name == 'ONLINEBERATUNG') {
                        sidOpti = cookieContent
                    }

                }
            }

            if (typeof cookieConfig.content === "undefined") {
                cookieContent += "%3B" + rp.chat.Core.getDeptId();
            }

            document.cookie = cookieConfig.name + "=" + cookieContent + "; expires=" + date.toGMTString() + "; path=/; domain=" + getFirstDomainName() + (window.location.protocol === 'https:' ? ";secure" : "") + " ;SameSite=Lax";

            cookieUpdatedOnlineberatung = (new Date()).getDate();
        };

        var updateCookieOnlineBeratung = function () {
            if (typeof config.isDeptNotInCookieGroup !== 'undefined') {
                setCookieOnlineBeratung(getCookieOnlineBeratung());
            }
        };

        var getCookieOnlineBeratung = function (name) {
            var cookieName = (typeof name !== "undefined") ? name : "ONLINEBERATUNG";
            return getCookieContent(cookieName + "=");
        };

        var getCookieDeptList = function () {
            var content = getCookieOnlineBeratung();
            var deptList = "";

            if (content) {
                deptList = content.substring((content.indexOf("%3B") !== -1 ? 16 : 13), content.length)
            }

            return deptList;
        };

        var transferExtCookieOnlineBeratung = function () {
            if (typeof config.cookie !== 'undefined') {
                Object.keys(config.cookie).forEach(function (key) {
                    var cookieContent = getCookieOnlineBeratung(config.cookie[key].name);
                    var extCookieContent = (typeof config.extCookieOnlineberatung !== "undefined") ? config.extCookieOnlineberatung : "";
                    if (!cookieContent && extCookieContent) {
                        var ablauf = new Date();
                        var gueltigkeitinTagen = ablauf.getTime() + (config.cookie[key].lifetime * 1000);
                        ablauf.setTime(gueltigkeitinTagen);
                        var firstDomain = getFirstDomainName();
                        document.cookie = config.cookie[key].name + "=" + extCookieContent + "; expires=" + ablauf.toGMTString() + "; path=/; domain=" + firstDomain + (window.location.protocol === 'https:' ? ";secure" : "") + " ;SameSite=Lax";
                    }
                });
            }
        };

        var isValidCookieOnlineBeratung = function () {
            if (typeof config.cookie !== 'undefined') {
                Object.keys(config.cookie).forEach(function (key) {
                    var content = getCookieOnlineBeratung(config.cookie[key].name);

                    if (!content) {
                        setCookieOnlineBeratung(undefined, key);
                    } else {
                        if (key === "default") {
                            sidOpti = content.substring(0, 13);
                        }

                        var deptList = content.substring((content.indexOf("%3B") !== -1 ? 16 : 13), content.length);
                        var cookieContentSplit = deptList.split("%3B");

                        // Prüfen ob die DeptID bereits vorhanden ist.
                        if (cookieContentSplit.indexOf(rp.chat.Core.getDeptId().toString()) === -1) {
                            updateCookieOnlineBeratung(key);
                        }
                    }
                });

            }
        };

        var getCookieContent = function (name) {
            var cookies = document.cookie;
            var startPos = cookies.indexOf(name);

            if (startPos != -1) {
                startPos += name.length;
                var endPos = (cookies.indexOf(";", startPos) != -1) ? cookies.indexOf(";", startPos) : cookies.length;
                var cookie = cookies.substring(startPos, endPos);
                if (cookie != "" && cookie.trim().length != 0) {
                    return cookie;
                }
            }
            return false;
        };

        this.getPagename = function () {
            return (typeof rpChatConfig !== "undefined" && typeof rpChatConfig.pagename !== "undefined") ? rpChatConfig.pagename : "";
        };

        this.getTextLinkCaption = function () {
            return (typeof rpChatConfig !== "undefined" && typeof rpChatConfig.textLinkCaption !== "undefined") ? rpChatConfig.textLinkCaption : "";
        };

        this.getFirstDomainName = function () {
            return getFirstDomainName();
        };

        this.isDebugLogEnable = function () {
            return rpChatConfig?.debug === true;
        };

        const getFirstDomainName = function () {
            const hostname = window.location.hostname;

            const specialTLDs = [
                ".co.uk", ".co.jp", ".com.au", ".com.br", ".co.nz", ".co.cn", ".co.kr",
                ".com.cn", ".co.th", ".com.my", ".com.sg", ".com.vn"
            ];
            const isSpecialTLD = specialTLDs.some((end) => hostname.endsWith(end));

            return hostname.split(".").slice(isSpecialTLD ? -3 : -2).join(".");
        };

        this.getTabActive = function () {
            return tabActive;
        };

        this.writeCookie = function (name, content) {
            document.cookie = name + "=" + content + "; path=/" + (window.location.protocol === 'https:' ? ";secure" : "") + " ;SameSite=Lax";
        };

        var getBrowserPrefix = function () {

            // Check for the unprefixed property.
            if ('hidden' in document) {
                return null;
            }

            // All the possible prefixes.
            var browserPrefixes = ['moz', 'ms', 'o', 'webkit'];

            for (var i = 0; i < browserPrefixes.length; i++) {
                var prefix = browserPrefixes[i] + 'Hidden';
                if (prefix in document) {
                    return browserPrefixes[i];
                }
            }

            return null;
        };

        var hiddenProperty = function (prefix) {
            if (prefix) {
                return prefix + 'Hidden';
            } else {
                return 'hidden';
            }
        };

        var visibilityEvent = function (prefix) {
            if (prefix) {
                return prefix + 'visibilitychange';
            } else {
                return 'visibilitychange';
            }
        };

        var registerEventMessages = function () {
            window.addEventListener('message', (e) => {
                if (!e.originalEvent || !e.originalEvent.data || !e.originalEvent.origin || !rp.chat.Core.getRPDomain() || !rp.chat.Core.getRPDomain('dialog')) {
                    return;
                }

                if (rp.chat.Core.getRPDomain().replace('/system', '') !== e.originalEvent.origin && rp.chat.Core.getRPDomain('dialog').replace('/system', '') !== e.originalEvent.origin) {
                    return;
                }

                const data = e.originalEvent.data;
                switch (data.type) {
                    case 'changeElementHeightRelative':
                        const el = domHelper(data.id)[0];
                        if (el !== undefined) {
                            el.style.height = el.offsetHeight + data.size + 'px';
                        }
                        break;
                    default:
                        break;
                }
            });
        };

        var triggerEvent = function (el, type, payload) {
            const e = document.createEvent("CustomEvent");
            e.initCustomEvent(type, true, true, payload);
            el.dispatchEvent(e);
        };

        const updateFootprint = function() {
            if ( typeof config.footprint_id !== 'undefined' ) {
                const url = urlCollect + '/scripts/getinfo.php';
                const status = (config.statusbutton.text.onlinestatus || config.statusbutton.video.onlinestatus ) ? 1 : 0;

                const params = {
                    action: 'update_footprint',
                    visitor_id: sessionrp,
                    footprint_id: config.footprint_id,
                    status: status,
                };

                networkHelper.getJSON(url, params);
            }
        };

        var prefix = getBrowserPrefix();
        var hidden = hiddenProperty(prefix);
        var visibilityEvent = visibilityEvent(prefix);
        var tabActive = 1;

        if (document[hidden] !== undefined) {
            document.addEventListener(visibilityEvent, function () {
                if (!document[hidden]) {
                    tabActive = 1;
                    if (!rp.chat.Core.isPopupLayer2() && rp.chat.LayerChat.getChatLayerIsActive()) {
                        rp.chat.Core.loadStatus(1);
                    }
                    if (typeof rp.chat.LayerChatModuleV2 == "function") { // layer v2
                        if (rp.chat.LayerChat.getChatLayerIsActive() && !rp.chat.LayerChat.getIsChimeActive()) {
                            rp.chat.Core.loadLayerChatModuleV2(function () {
                                triggerEvent(document, 'rp-start-chat');
                                rp.chat.LayerChat.setReloadChatContent();
                                rp.chat.LayerChat.setLayer();
                            });
                        } else if (rp.chat.LayerChat.getChatLayerIsActive() === 0) {
                            const wrapper = domHelper('#realpersonChatWrapper');
                            if (wrapper.isVisible()) {
                                rp.chat.LayerChat.closeChat();
                            }
                            if (rp.chat.isInviteEnabled()) {
                                clearTimeout(rp.chat.Core.resLoadStatus);
                                rp.chat.startLoadStatus();
                            }
                        }
                    }
                } else {
                    tabActive = 0;
                    if (typeof rp.chat.LayerChatModuleV2 == "function"
                        && rp.chat.isInviteEnabled()
                        && rp.chat.LayerChat.getChatLayerIsActive() === 0
                    ) {
                        clearTimeout(rp.chat.Core.resLoadStatus);
                    }
                }
            });
        }

        this.versionizeUrl = function (input) {
            const url = new URL(input);
            url.searchParams.set('v', config.version);
            return url.toString();
        }

        var validateLocalSession = function () {
            const currentTime = Math.floor(Date.now() / 1000);
            const currentSessionTime = rp.chat.Storage.get('default', 'sessionTime');

            const sessionValidation = sessionStorage.getItem('rp-chat-storage-session-validation');
            if (sessionValidation !== null) {
                sessionValidationExpiredInSeconds *= 3;
            }
            removeLocalSession = currentSessionTime === '' || (currentTime - currentSessionTime) > sessionValidationExpiredInSeconds;
            if (getCookieContent("REALPERSON_SESSION=") === false) {
                removeLocalSession = false;
            }
            sessionStorage.setItem('rp-chat-storage-session-validation', '1');
        }

        var updateLocalSessionValidation = function () {
            var currentTime = Math.floor(Date.now() / 1000);
            rp.chat.Storage.set('default', 'sessionTime', currentTime);
            rp.chat.TimerProxy.setTimeout(function () {
                updateLocalSessionValidation();
            }, sessionValidationTimerInSeconds * 1000);
        }

        validateLocalSession();
        updateLocalSessionValidation();

        isValidCookieSession();
        this.loadConfig();
    };

    rp.chat.ChatStorage = function () {
        {
            var storage = window.localStorage;
            var sessionKey = 'rp-chat-storage';
            var ttl = 30 * 60 * 1000; // milliseconds

            this.set = function (view, key, value) {
                this.checkExpire();
                var data = this.get();
                if (typeof data[view] === "undefined") {
                    data[view] = {};
                }
                data[view][key] = {
                    value: value,
                    ttl: (new Date()).getTime() + ttl
                };
                storage.setItem(sessionKey, JSON.stringify(data));
            };

            this.get = function (view, key) {
                this.checkExpire();
                var data = JSON.parse(storage.getItem(sessionKey));
                if (typeof view !== "undefined" && typeof key !== "undefined") {
                    if (data !== null && data.hasOwnProperty(view) && data[view].hasOwnProperty(key)) {
                        return data[view][key].value;
                    }
                    return '';
                } else if (typeof view !== "undefined" && typeof key === "undefined") {
                    if (data !== null && data.hasOwnProperty(view)) {
                        return data[view];
                    }
                    return {};
                } else if (typeof view === "undefined" && data !== null) {
                    return data;
                }
                return {};
            };

            this.has = function (view, key) {
                this.checkExpire();
                var data = JSON.parse(storage.getItem(sessionKey));
                if (typeof view !== "undefined" && typeof key !== "undefined") {
                    return data !== null && data.hasOwnProperty(view) && data[view].hasOwnProperty(key);
                }
                return false;
            }

            this.remove = function (view, key) {
                this.checkExpire();
                var data = JSON.parse(storage.getItem(sessionKey));
                if (typeof view !== "undefined" && data !== null && data.hasOwnProperty(view)) {
                    if (typeof key !== "undefined" && data[view].hasOwnProperty(key)) {
                        delete data[view][key];
                    } else if (typeof key === "undefined") {
                        delete data[view];
                    }
                    localStorage.setItem(sessionKey, JSON.stringify(data));
                }
            };

            this.destroy = function () {
                const data = JSON.parse(storage.getItem(sessionKey));
                const sessionTime = data?.default?.sessionTime;

                storage.removeItem(sessionKey);

                if (sessionTime) {
                    this.set('default', 'sessionTime', sessionTime);
                }
            };

            this.checkExpire = function () {
                var data = JSON.parse(storage.getItem(sessionKey));

                for (var view in data) {
                    for (var key in data[view]) {
                        if (typeof data[view][key].value !== "undefined" && typeof data[view][key].ttl !== "undefined") {
                            if (data[view][key].ttl < (new Date()).getTime()) {
                                delete data[view][key];
                            }
                        }
                    }
                }
                localStorage.setItem(sessionKey, JSON.stringify(data));
            }
        }
    };

    rp.chat.ChatUpload = function (data) {
        const settings = data;
        const that = this;

        let enabled = true;
        let req = null;
        let reachedProgress100 = false;

        this.selectFile = function () {
            const uploadInput = document.querySelector("#" + settings.name);
            if (uploadInput) {
                uploadInput.click();
            }
        }

        this.uploadFile = function () {
            reachedProgress100 = false;
            const progress = document.querySelector('#' + settings.name + '-progress');
            if (progress) {
                progress.style.display = 'flex';
            }

            const progressBar = document.querySelector('#' + settings.name + '-progress-bar');
            if (progressBar) {
                progressBar.style.width = '0%';
            }

            const fileElement = document.querySelector("#" + settings.name);
            if (!fileElement) {
                return;
            }
            const file = fileElement.files[0];

            // reset element
            fileElement.value = '';

            if (!that.checkFileExtension(file.name.toLowerCase())) {
                if (settings.onExtError && typeof settings.onExtError === 'function') {
                    settings.onExtError();
                }
                return;
            }

            if (!that.checkFileSize(file.size)) {
                if (settings.onSizeError && typeof settings.onSizeError === 'function') {
                    settings.onSizeError();
                }
                return;
            }

            const url = settings.url + '?imgfile=' + file.name + '&chatId=' + settings.chatId
            const data = {
                imgfile: file
            }
            const callBackList = {
                load: that.loadEvent,
                progress: that.progressEvent
            }
            const headers = {
                'Accept': 'application/json'
            }
            req = rp.chat.Network.postWithCallback(url, data, callBackList, headers);
        }

        this.loadEvent = function (event) {
            if (settings.onComplete) {
                settings.onComplete(JSON.parse(req.response));
            }
            const progress = document.querySelector('#' + settings.name + '-progress');
            if (progress) {
                progress.style.display = 'none';
            }
        }

        this.progressEvent = function (event) {
            const percent = Math.round((event.loaded / event.total) * 100);
            document.querySelector('#' + settings.name + '-progress-bar').style.width = percent + '%';
            if (percent === 100 && !reachedProgress100 && settings.onProgress100) {
                settings.onProgress100();
            }
            reachedProgress100 = percent === 100;
        }

        this.disable = function () {
            enabled = false;
            this.changeButtonStatus();
        }

        this.enable = function () {
            enabled = true;
            this.changeButtonStatus();
        }

        this.checkFileExtension = function (fileName) {
            const extension = fileName.split('.').pop();
            return settings.allowedExtensions.includes(extension);
        }

        this.checkFileSize = function (size) {
            return size < settings.maxSize;
        }

        this.changeButtonStatus = function() {
            const button = document.querySelector(settings.button);
            if (button) {
                button.disabled = !enabled;
            }
        }

        this.createInput = function () {
            const inputFile = document.createElement('INPUT');
            inputFile.setAttribute('type', 'file');
            inputFile.setAttribute('id', settings.name);
            inputFile.style.display = 'none';

            settings.allowedExtensions.forEach(element => inputFile.accept += "." + element + ",");

            document.querySelector(settings.button).appendChild(inputFile);
        }

        this.createProgressBar = function () {
            const progressElement = document.createElement('DIV');
            progressElement.setAttribute('id', settings.name + '-progress');
            progressElement.setAttribute('class', 'progress');
            progressElement.style.width = '100%';
            progressElement.style.display = 'none';

            const progressBarElement = document.createElement('DIV');
            progressBarElement.setAttribute('id', settings.name + '-progress-bar');
            progressBarElement.setAttribute('class', 'progress-bar');
            progressBarElement.style.width = '0%';

            progressElement.appendChild(progressBarElement);

            document.querySelector(settings.progressBar).appendChild(progressElement);
        }

        this.addListener = function () {
            document.querySelector(settings.button).addEventListener('click', this.selectFile);
            document.querySelector("#" + settings.name).addEventListener('change', this.uploadFile);
        }

        this.removeListener = function () {
            document.querySelector(settings.button)?.removeEventListener('click', this.selectFile);
            document.querySelector("#" + settings.name)?.removeEventListener('change', this.uploadFile);
        }

        this.createInput();
        this.createProgressBar();
        this.addListener();
    };

    rp.chat.TcfCheck = function () {
        {
            var tcfIsAvailable = false;
            var allowed = false;
            var vendorId = 1064;

            var frame;
            var cmpFrame;
            const cmpCallbacks = {};

            this.receiveTcfData = function(tcData, success)
            {
                allowed = false;
                if (success) {
                    if (
                        tcData.publisher.consents['1'] !== 'undefined'
                        && typeof tcData.vendor.consents[vendorId] !== 'undefined'
                    ) {
                        allowed = tcData.publisher.consents['1'] && tcData.vendor.consents[vendorId];
                    }
                }
            }

            this.setTCFFunctions = function() {
                if (typeof __tcfapi === 'function') {
                    __tcfapi('addEventListener', 2, this.receiveTcfData);
                    tcfIsAvailable = true;
                }
            }

            this.setIsAllowed = function (isAllowed) {
                allowed = isAllowed;
            };

            this.isAllowed = function () {
                return allowed;
            };

            /**
             * if it is nested in an iFrame, find the parent frame
             */
            this.foundFrame = function () {
                frame = window;
                while (frame) {
                    try {
                        if (frame.frames['__tcfapiLocator']) {
                            cmpFrame = frame;
                            break;
                        }
                    } catch (ignore) { }

                    if (frame === window.top) {
                        break;
                    }
                    frame = frame.parent;
                }
            }

            /**
             * receive messages from parent frame
             * @param event
             */
            this.postMessageHandler = function (event) {
                let json = {};
                try {
                    json = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
                } catch (ignore) {}

                const payload = json.__tcfapiReturn;
                if (payload) {
                    if (typeof cmpCallbacks[payload.callId] === 'function') {
                        cmpCallbacks[payload.callId](payload.returnValue, payload.success);
                        cmpCallbacks[payload.callId] = null;
                    }
                }
            }

            /**
             * Set up a __tcfapi proxy method to do the postMessage and map the callback.
             * From the caller's perspective, this function behaves identically to the
             * CMP API's __tcfapi call
             */
            this.setTCFProxyMethod = function () {
                if (frame != window) {
                    window.__tcfapi = function (cmd, version, callback, arg) {
                        if (!cmpFrame) {
                            callback({msg: 'CMP not found'}, false);
                        } else {
                            const callId = Math.random() + '';
                            const msg = {
                                __tcfapiCall: {
                                    command: cmd,
                                    parameter: arg,
                                    version: version,
                                    callId: callId,
                                },
                            };
                            cmpCallbacks[callId] = callback;
                            cmpFrame.postMessage(msg, '*');
                        }
                    };

                    window.addEventListener('message', this.postMessageHandler, false);
                }
            }

            this.foundFrame();
            this.setTCFProxyMethod();
            this.setTCFFunctions();

            if (tcfIsAvailable === false) {
                setTimeout(function () {
                    rp.chat.Tcf.setTCFFunctions();
                }, 1000);
            }
        }
    }

    rp.chat.TimerProxyModule = function () {
        try {
            const workerContent = "const fakeIdToId = {}; onmessage = function (event) {const data = event.data, name = data.name, fakeId = data.fakeId;let time;if (data.hasOwnProperty('time')) {time = data.time;}switch (name) {case 'setTimeout':fakeIdToId[fakeId] = setTimeout(function () {postMessage({ fakeId: fakeId });if (fakeIdToId.hasOwnProperty (fakeId)) {delete fakeIdToId[fakeId];}}, time);break;}} ";
            const blobURL = URL.createObjectURL(new Blob([workerContent], { type: 'application/javascript' }));

            const worker = new Worker(blobURL);

            const fakeIdToCallback = {};
            const maxFakeId = 0x7FFFFFFF;
            let lastFakeId = 0;

            const getFakeId = function () {
                do {
                    if (lastFakeId === maxFakeId) {
                        lastFakeId = 0;
                    } else {
                        lastFakeId++;
                    }
                } while (fakeIdToCallback.hasOwnProperty(lastFakeId));

                return lastFakeId;
            }

            this.setTimeout = function (callback, time) {
                const fakeId = getFakeId();

                fakeIdToCallback[fakeId] = {
                    callback: callback,
                    parameters: Array.prototype.slice.call(arguments, 2),
                    isTimeout: true
                };

                worker.postMessage ({
                    name: 'setTimeout',
                    fakeId: fakeId,
                    time: time
                });

                return fakeId;
            };

            worker.onmessage = function (event) {
                const data = event.data;
                const fakeId = data.fakeId;
                let request,parameters, callback;

                if (fakeIdToCallback.hasOwnProperty(fakeId)) {
                    request = fakeIdToCallback[fakeId];
                    callback = request.callback;
                    parameters = request.parameters;

                    if (request.hasOwnProperty ('isTimeout') && request.isTimeout) {
                        delete fakeIdToCallback[fakeId];
                    }
                }

                if (typeof (callback) === 'string') {
                    try {
                        callback = new Function(callback);
                    } catch (error) {
                        console.log('Error parsing callback code string: ', error);
                    }
                }

                if (typeof (callback) === 'function') {
                    callback.apply(window, parameters);
                }
            }

            worker.onerror = function (event) {
                console.log(event);
            };

        } catch (error) {
            console.log('rpTimerProxy initialisation failed');
            this.setTimeout = function (callback, time) {
                window.setTimeout(callback, time);
            };
        }
    }

    rp.chat.HtmlSanitizer = (function () {
        function stringToDom(str) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(str, 'text/html');

            return doc.body || document.createElement('body');
        }

        function removeScripts(dom) {
            dom.querySelectorAll('script').forEach(el => el.remove());
        }

        function isDangerous(name, value) {
            const val = value.replace(/\s+/g, '').toLowerCase();

            if (name.startsWith('on')) return true;

            if (['src', 'href', 'xlink:href'].includes(name)) {
                if (val.startsWith('javascript:') || val.startsWith('data:')) {
                    return true;
                }
            }
        }

        function removeAttributes(el) {
            const attributes = el.attributes;

            for (const {name, value} of attributes) {
                if (!isDangerous(name, value)) continue;
                el.removeAttribute(name);
            }
        }

        function crawl(node, cb) {
            const children = node.children;
            for (const child of children) {
                cb(child);
                crawl(child, cb);
            }
        }

        function clean(htmlString) {
            const dom = stringToDom(htmlString);

            removeScripts(dom);
            crawl(dom, (node) => removeAttributes(node));

            return dom.innerHTML;
        }

        return {
            clean,
        }
    })();

    rp.chat.Network = (function (fetch) {
        /**
         * Sends GET request, expects return to be application/json or 204
         * @param {string} url
         * @param {Object?} queryParams
         * @param {Object?} headers
         * @return {Promise<any>}
         */
        function getJSON(url, queryParams, headers) {
            queryParams = queryParams || {};
            headers = headers || {};
            let finalUrl = new URL(url);

            if (typeof rp.chat.Core !== 'undefined' && rp.chat.Core.token !== '') {
                headers = Object.assign({}, headers, {
                    'Authorization': `Bearer ${rp.chat.Core.token}`,
                });
            }

            Object.keys(queryParams).forEach((key) => {
                finalUrl.searchParams.append(key, queryParams[key]);
            });

            return fetch(finalUrl.toString(), {
                method: 'GET',
                headers: Object.assign({}, headers, {
                    'Accept': 'application/json',
                }),
                cache: 'no-cache',
                referrerPolicy: 'no-referrer',
                keepalive: true,
                credentials: 'omit',
            }).then(async (response) => {
                if (!response.ok) {
                    throw new Error('Failed request');
                }
                if (response.status === 204) {
                    await response.text(); // chromium "fix"
                    return new Promise((resolve) => {
                        resolve({});
                    });
                }
                return response.json();
            });
        }

        /**
         * Sends POST request as application/json, expects return to be application/json
         * @param {string} url
         * @param {Object} postData
         * @param {Object?} headers
         * @returns {Promise<any>}
         */
        function postJSON(url, postData, headers) {
            headers = headers || {};

            if (typeof rp.chat.Core !== 'undefined' && rp.chat.Core.token !== '') {
                headers = Object.assign({}, {
                    'Authorization': `Bearer ${rp.chat.Core.token}`,
                },
                headers);
            }

            return fetch(url, {
                method: 'POST',
                headers: Object.assign({}, headers, {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }),
                cache: 'no-cache',
                referrerPolicy: 'no-referrer',
                keepalive: true,
                credentials: 'omit',
                body: JSON.stringify(postData),
            }).then(async (response) => {
                if (!response.ok) {
                    throw new Error('Failed request');
                }
                if (response.status === 204) {
                    await response.text(); // chromium "fix"
                    return new Promise((resolve) => {
                        resolve({});
                    });
                }
                return response.json();
            });
        }

        /**
         * Sends POST request as multipart/form-data, expects no return
         * @param {string} url
         * @param {Object} postData
         * @param {Object?} headers
         * @returns {Promise<any>}
         */
        function postFormWithoutReturn(url, postData, headers) {
            headers = headers || {};

            if (typeof rp.chat.Core !== 'undefined' && rp.chat.Core.token !== '') {
                headers = Object.assign({}, headers, {
                    'Authorization': `Bearer ${rp.chat.Core.token}`,
                });
            }

            const formData = new FormData();
            Object.keys(postData).forEach((key) => {
                formData.append(key, postData[key]);
            });

            return fetch(url, {
                method: 'POST',
                headers: headers,
                cache: 'no-cache',
                referrerPolicy: 'no-referrer',
                keepalive: true,
                credentials: 'omit',
                body: formData,
            }).then(async (response) => {
                if (!response.ok) {
                    throw new Error('Failed request');
                }

                await response.text(); // chromium "fix"
            });
        }

        function postWithCallback(url, postData, callbackList, headers) {
            headers = headers || {};

            if (typeof rp.chat.Core !== 'undefined' && rp.chat.Core.token !== '') {
                headers = Object.assign({}, headers, {
                    'Authorization': `Bearer ${rp.chat.Core.token}`,
                });
            }

            const formData = new FormData();
            Object.keys(postData).forEach((key) => {
                formData.append(key, postData[key]);
            });

            const req = new XMLHttpRequest();
            req.open("POST", url);

            Object.keys(headers).forEach((key) => {
                req.setRequestHeader(key, headers[key])
            });

            if (callbackList.load && typeof callbackList.load === "function") {
                req.addEventListener('load', (event) => callbackList.load(event));
            }

            if (callbackList.progress && typeof callbackList.progress === "function") {
                req.upload.addEventListener('progress', (event) => callbackList.progress(event));
            }

            req.send(formData);
            return req;
        }

        function loadScript(url, elementId, integrity) {
            return new Promise((resolve) => {
                const element = document.createElement('script');
                element.src = url;
                element.addEventListener('load', resolve);

                if (elementId) {
                    element.id = elementId;
                }

                if (integrity) {
                    element.integrity = integrity;
                    element.crossOrigin = 'anonymous';
                }

                document.head.appendChild(element);
            });
        }

        function loadStyle(url, elementId, integrity) {
            return new Promise((resolve) => {
                const element = document.createElement('link');
                element.type = "text/css";
                element.rel = "stylesheet";
                element.href = url;
                element.addEventListener('load', resolve);

                if (elementId) {
                    element.id = elementId;
                }

                if (integrity) {
                    element.integrity = integrity;
                    element.crossOrigin = 'anonymous';
                }

                document.head.appendChild(element);
            });
        }

        /**
         * Sends GET request, expects return to be any text
         * @param {string} url
         * @return {Promise<string>}
         */
        function loadText(url) {
            return fetch(url, {
                method: 'GET',
                cache: 'default',
                referrerPolicy: 'no-referrer',
                keepalive: true,
                credentials: 'omit',
            }).then((response) => {
                if (!response.ok) {
                    throw new Error('Failed request');
                }
                if (response.status === 204) {
                    return new Promise(function (resolve) {
                        resolve('');
                    });
                }
                return response.text();
            });
        }

        return {
            getJSON,
            postJSON,
            postFormWithoutReturn,
            postWithCallback,
            loadScript,
            loadStyle,
            loadText,
        };
    })(window.fetch);

    !function() {
        const registeredEventList = [];

        const hash = Date.now().toString(36);
        const classNameAnimationFadeIn = `rpAnimationFadeIn${hash}`;
        const classNameAnimationFadeOut = `rpAnimationFadeOut${hash}`;

        // inject animations
        !function() {
            const cssAnimations = `
                @keyframes ${classNameAnimationFadeIn} {
                    0% { opacity: 0; }
                    100% { opacity: 1; }
                }
                .${classNameAnimationFadeIn} {
                    animation-duration: var(--animation-duration);
                    animation-fill-mode: forwards;
                    animation-direction: normal;
                    animation-name: ${classNameAnimationFadeIn};
                    opacity: 0;
                    --animation-duration: 400ms;
                }
                @keyframes ${classNameAnimationFadeOut} {
                    0% { opacity: 1; }
                    100% { opacity: 0; }
                }
                .${classNameAnimationFadeOut} {
                    animation-duration: var(--animation-duration);
                    animation-fill-mode: forwards;
                    animation-direction: normal;
                    animation-name: ${classNameAnimationFadeOut};
                    opacity: 0;
                    --animation-duration: 400ms;
                }
            `;

            const style = document.createElement('style');
            style.appendChild(document.createTextNode(cssAnimations));
            document.head.appendChild(style);
        }();

        function speedStringToAnimationDuration(speed) {
            switch (speed) {
                case 'slow': return '600ms';
                case 'fast': return '200ms';
                default: return Number.parseInt(speed) > 0 ? Number.parseInt(speed) + 'ms' : '400ms';
            }
        }

        function snakeCaseToCamelCase(text) {
            // foo-bar => fooBar
            return text.replace(/(?:^\w|[A-Z]|\b\w)/g, (char, index) => index === 0 ? char.toLowerCase() : char.toUpperCase()).replace(/-/g, '');
        }

        function DOM(input) {
            [].push.apply(this, Array.isArray(input) ? input : input && (input.nodeType || input === window) ? [input] : '' + input === input ? document.querySelectorAll(input) : undefined);
        }

        const domHelper = rp.chat.DOM = (input) => {
            return /^f/.test(typeof input) ? /c/.test(document.readyState) ? input() : domHelper(document).on('DOMContentLoaded', input) : new DOM(input);
        };

        rp.chat.DOM.prototype = DOM.prototype = {
            length: 0,

            debugRegisteredEventList: registeredEventList,

            replaceScriptTag: function() {
                this.each(el => {
                    let scripts = el.getElementsByTagName('script');

                    for (let i = 0; i < scripts.length; i++) {
                        let oldScript = scripts[i];
                        const newScript = document.createElement('script');
                        newScript.text = oldScript.text;

                        for (let i = 0; i < oldScript.attributes.length; i++) {
                            const attr = oldScript.attributes[i];
                            newScript.setAttribute(attr.name, attr.value);
                        }
                        oldScript.parentNode.replaceChild(newScript, oldScript);
                    }
                });
            },

            each: function (callback) {
                [].forEach.call(this, callback);
                return this;
            },

            map: function (callback, data) {
                return [].map.call(this, callback, data);
            },

            find: function (selector) {
                return new DOM([].flatMap.call(this, entry => [...entry.querySelectorAll(selector)]));
            },

            not: function (filter) {
                // string selector
                if (filter === filter + '') {
                    return new DOM([].filter.call(this, entry => !entry.matches(filter)));
                }

                // raw element list
                if (Array.isArray(filter) || filter instanceof NodeList) {
                    return new DOM([].filter.call(this, entry => ![...filter].includes(entry)));
                }

                // raw element
                if (filter && filter.nodeType) {
                    return new DOM([].filter.call(this, entry => entry !== filter));
                }

                // DOM list
                if (filter instanceof DOM) {
                    return new DOM([].filter.call(this, entry => ![].includes.call(filter, entry)));
                }

                throw new Error('Invalid filter passed');
            },

            closest: function (selector) {
                const closest = [].flatMap.call(this, entry => [entry.closest(selector)]);
                return new DOM(closest.filter(entry => entry !== null));
            },

            first: function () {
                return new DOM([].slice.call(this, 0, 1));
            },

            last: function () {
                return new DOM([].slice.call(this, -1));
            },

            on: function (eventName, childFilter, callback, once) {
                if (childFilter instanceof Function) {
                    once = once || callback;
                    callback = childFilter;
                    childFilter = null;
                }

                if (!callback instanceof Function) {
                    throw Error('Missing callback');
                }

                once = !!once;

                if (
                    childFilter
                    && ([].includes.call(this, document) || [].includes.call(this, window))
                    && ['click', 'keyup', 'keydown', 'keypress'].includes(eventName)
                ) {
                    throw Error('Do not bind delegated input events on document or window');
                }

                // split into dedicated handlers if multiple event names
                if (eventName.split(' ').length > 1) {
                    eventName.split(' ').forEach((name) => {
                        if (rp.chat.Core.isDebugLogEnable()) {
                            console.log(name);
                        }
                        this.on(name, childFilter, callback, once)
                    });
                    return;
                }

                this.each(element => {
                    const eventListener = (event) => {
                        if (!childFilter) {
                            event.stopPropagation();
                            return callback.apply(element, [event]);
                        }
                        const children = [...element.querySelectorAll(childFilter)];
                        for (let i = 0; i < children.length; i++) {
                            let current = event.target;
                            while (current) {
                                if (children.includes(current)) {
                                    if (['click', 'keyup', 'keydown', 'keypress'].includes(event.type)) {
                                        event.stopImmediatePropagation();
                                    }

                                    event.stopPropagation();
                                    return callback.apply(current, [event]);
                                }
                                current = current.parentElement;
                            }
                        }
                    };

                    registeredEventList.push({element, childFilter, eventName, callback, eventListener});
                    element.addEventListener(eventName, eventListener, { once: once });
                });

                return this;
            },

            once: function (eventName, childFilter, callback) {
                return this.on(eventName, childFilter, callback, true);
            },

            off: function (eventName, childFilter, callback) {
                if (childFilter instanceof Function) {
                    callback = childFilter;
                    childFilter = null;
                }

                // split into dedicated handlers if multiple event names
                if (eventName.split(' ').length > 1) {
                    eventName.split(' ').forEach((name) => this.off(name, childFilter, callback));
                    return;
                }

                this.each((element) => {
                    const events = registeredEventList
                        .filter((event) => event.element === element)
                        .filter((event) => !childFilter || event.childFilter === childFilter)
                        .filter((event) => !callback || event.callback === callback);

                    events.forEach((event) => element.removeEventListener(eventName, event.eventListener));
                });

                return this;
            },

            trigger: function (eventName, payload) {
                switch (eventName) {
                    case 'reset':
                        this.each(el => el.reset());
                        break;
                    case 'focus':
                        this.each(el => el.focus());
                        break;
                    default:
                        const event = new CustomEvent(eventName, { bubbles: true, detail: payload || null });
                        this.each(el => el.dispatchEvent(event));
                }

                return this;
            },

            text: function (content) {
                if (content === content + '') {
                    this.each(element => element.textContent = content);
                    return this;
                }
                return this.length > 0 ? this[0].textContent : '';
            },

            html: function (content) {
                if (content === content + '') {
                    this.each(el => el.innerHTML = content);
                    this.replaceScriptTag();

                    return this;
                }
                return this.length > 0 ? this[0].innerHTML : '';
            },

            addClass: function (classes) {
                this.each(el => el.classList.add(...classes.split(' ')));
                return this;
            },

            removeClass: function (classes) {
                this.each(el => el.classList.remove(...classes.split(' ')));
                return this;
            },

            hasClass: function (cl) {
                return this.map(el => el.classList.contains(cl)).includes(true);
            },

            append: function (element) {
                if (element instanceof Element) {
                    this.each(el => el.append(element));
                }

                if (element instanceof DOM) {
                    this.each(el => {
                        element.each(el2 => el.append(el2));
                    });
                }

                return this;
            },

            appendTo: function (element) {
                // string selector
                if (element === element + '') {
                    element = document.querySelector(element);
                }

                // raw element list
                if (Array.isArray(element) || element instanceof NodeList) {
                    element = [...element][0];
                }

                // DOM list
                if (element instanceof DOM) {
                    element = element[0];
                }

                if (!element) {
                    throw new Error('Invalid element passed');
                }

                this.each(el => element.append(el));

                return this;
            },

            prepend: function(element) {
                if (element instanceof Element) {
                    this.each(el => el.prepend(element));
                }

                if (element instanceof DOM) {
                    this.each(el => {
                        element.each(el2 => el.prepend(el2));
                    });
                }
                return this;
            },

            after: function(element) {
                if (element instanceof Element) {
                    this.each(el => el.after(element));
                }

                if (element instanceof DOM) {
                    this.each(el => {
                        element.each(el2 => el.after(el2));
                    });
                }

                return this;
            },

            before: function (element) {
                if (element instanceof Element) {
                    this.each(el => el.before(element));
                }

                if (element instanceof DOM) {
                    this.each(el => {
                        element.each(el2 => el.before(el2));
                    });
                }

                return this;
            },

            remove: function () {
                this.each(el => el.parentElement?.removeChild(el));
            },

            get: function (index) {
                return this[index] || null;
            },

            parent: function () {
                return this.length > 0 ? domHelper(this[0].parentElement) : null;
            },

            fadeIn: function (speed, callback) {
                this.once('animationend', (event) => {
                    event.target.classList.remove(classNameAnimationFadeIn);
                    if (event.target.getAttribute('style') === '') { event.target.removeAttribute('style'); }
                    callback && callback();
                });

                this.each((el) => {
                    el.style.setProperty('--animation-duration', speedStringToAnimationDuration(speed));
                    el.style.display = el[`rpOriginalDisplayFade${hash}`] || el.style.display;
                    delete el[`rpOriginalDisplayFade${hash}`];
                    el.classList.add(classNameAnimationFadeIn);
                });

                return this;
            },

            fadeOut: function (speed, callback) {
                this.once('animationend', (event) => {
                    event.target.classList.remove(classNameAnimationFadeOut);
                    event.target[`rpOriginalDisplayFade${hash}`] = event.target.style.display;
                    event.target.style.display = 'none';
                    callback && callback();
                });

                this.each((el) => {
                    el.style.setProperty('--animation-duration', speedStringToAnimationDuration(speed));
                    el.classList.add(classNameAnimationFadeOut);
                });

                return this;
            },

            hide: function () {
                this.each((el) => {
                    el[`rpOriginalDisplayHide${hash}`] = el.style.display;
                    el.style.display = 'none';
                });

                return this;
            },

            show: function () {
                this.each((el) => {
                    el.style.display = el[`rpOriginalDisplayHide${hash}`] || '';
                    delete el[`rpOriginalDisplayHide${hash}`];
                    if (el.getAttribute('style') === '') { el.removeAttribute('style'); }
                });

                return this;
            },

            attr: function(attributeName, value) {
                if (value === undefined) {
                    return this.length > 0 ? this[0].getAttribute(attributeName) : null;
                }

                this.each((el) => {
                    el.setAttribute(attributeName, value);
                });

                return this;
            },

            removeAttr: function(attributeName) {
                this.each((el) => {
                    el.removeAttribute(attributeName);
                });

                return this;
            },

            prop: function(propertyName, value) {
                const finalPropertyName = snakeCaseToCamelCase(propertyName);

                if (value === undefined) {
                    return this.length > 0 && finalPropertyName in this[0] ? this[0][finalPropertyName] : null;
                }

                this.each((el) => {
                    if (!finalPropertyName in el) { return; }
                    el[finalPropertyName] = value;
                });

                return this;
            },

            removeProp: function(propertyName) {
                const finalPropertyName = snakeCaseToCamelCase(propertyName);

                this.each((el) => {
                    el[finalPropertyName] = null;
                });

                return this;
            },

            data: function(name, value) {
                if (name === undefined) {
                    const ret= {};

                    this.each((el) => {
                        for (const key in el.dataset) {
                            ret[key] = el.dataset[key];
                        }
                    });

                    return ret;
                }

                const finalName = snakeCaseToCamelCase(name);

                if (value === undefined) {
                    return this.length > 0 && finalName in this[0].dataset ? this[0].dataset[finalName] : undefined;
                }

                this.each((el) => {
                    if (!finalName in el.dataset) { return; }
                    el.dataset[finalName] = value;
                });

                return this;
            },

            val: function(value) {
                if (value === undefined) {
                    return this.length > 0 ? this[0].value || '' : null;
                }

                this.each((el) => {
                    el.value = value;
                });
            },

            isVisible: function() {
                return [].filter.call(this, (el) => {
                    return (el.offsetWidth || el.offsetHeight || el.getClientRects().length) > 0;
                }).length > 0;
            },

            getBoundingClientRect: function() {
                const el = this[0];
                if (!el) {
                    return new DOMRect();
                }

                // already visible
                if ((el.offsetWidth || el.offsetHeight || el.getClientRects().length) > 0) {
                    return el.getBoundingClientRect();
                }

                const origVisibility = el.style.visibility;
                el.style.visibility = 'hidden';
                const origDisplay = el.style.display;
                el.style.display = 'block';
                const rect = el.getBoundingClientRect();
                el.style.visibility = origVisibility;
                el.style.display = origDisplay;
                return rect;
            },

            clone: function() {
                return new DOM(this.map((el) => el.cloneNode(true)));
            },

            width: function() {
                const el = this[0];
                if (!el) {
                    return 0;
                }
                return el.offsetWidth;
            },

            splice: [].splice
        };
    }();

    if (window.rpChatConfig && typeof rpChatConfig.checkTrackingAllowed !== 'function') {
        // convert non-callback to callback
        var allowed = typeof rpChatConfig.checkTrackingAllowed === 'undefined' ? true : !!rpChatConfig.checkTrackingAllowed;
        rp.chat.Tcf = new rp.chat.TcfCheck();
        rp.chat.Tcf.setIsAllowed(allowed);

        rpChatConfig.checkTrackingAllowed = function () {
            // check for onetrust support
            if (
                typeof OnetrustActiveGroups === 'string'
                && typeof rp.chat.Core !== 'undefined'
                && typeof rp.chat.Core.getConfig().useOnetrustActiveGroups !== 'undefined'
            ) {
                var allowed = OnetrustActiveGroups.includes(rp.chat.Core.getConfig().useOnetrustActiveGroups)
                rp.chat.Tcf.setIsAllowed(allowed);
            }

            return rp.chat.Tcf.isAllowed();
        }
    }

    if (rp.chat.isInitiated === false) {
        rp.chat.loadModules();
    }
}